import React, { useState , useRef} from 'react';
import Map from './components/Map';
import Modal from "./components/Modal";
import AlertModal from './components/AlertModal';
import MeasurementTool from './components/MeasurementTool';
import './App.css';

const App = () => {
  const [selectedPrefecture, setSelectedPrefecture] = useState('');
  const [shapes, setShapes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [selectedPrefectureName, setSelectedPrefectureName] = useState('');
  const [isMeasurementToolVisible, setIsMeasurementToolVisible] = useState(false);
  const [isMeasurementButtonPressed, setIsMeasurementButtonPressed] = useState(false);
  const [viewDeleteIcon, setDeleteIcon] = useState(false);
  const [viewTrashCan, setTrashCan] = useState(false);

  const fabricCanvasRef = useRef(null);


  // 都道府県を変更する関数
  const handlePrefectureChange = (prefecture, prefectureName) => {
    setSelectedPrefecture(prefecture);
    setSelectedPrefectureName(prefectureName);
    setShapes([]);
    setShowModal(false); // モーダルを閉じる
  };

  // モーダルを表示する関数
  const showModalFunc = () => {
    const remainingShapes = 20 - shapes.length;
    if (remainingShapes < 20) {
      setShowAlertModal(true);
    } else {
      setShowModal(true);
    }
  };

  // アラートモーダルの確認ボタンの処理
  const handleAlertConfirm = () => {
    setShowAlertModal(false);
    setShowModal(true);
  };

  // アラートモーダルのキャンセルボタンの処理
  const handleAlertCancel = () => {
    setShowAlertModal(false);
    alert('ほぞんしてください。');
  };

  // 測定ツールの表示・非表示を切り替える関数
  const handleMeasurementButtonClick = () => {
      //キャンバスのアクティブオブジェクトを解除
      const canvas = fabricCanvasRef.current;
      canvas.discardActiveObject();
      canvas.requestRenderAll(); 

      setIsMeasurementToolVisible(!isMeasurementToolVisible);
      setIsMeasurementButtonPressed(!isMeasurementButtonPressed);
  };

  const appClassName = `App ${isMeasurementButtonPressed ? 'is_disable' : ''} ${!selectedPrefecture ? 'no_select' : ''}`.trim();

  return (
    <div className={appClassName}>
      <div className="App__menuArea">
        <button onClick={showModalFunc} className='selectpref'>都道府県を選ぶ</button>
        <p className='headLine'>図形を置く</p>
      </div>
      <div className="App__canvasArea">
        {isMeasurementToolVisible && <MeasurementTool />}
        <Map prefecture={selectedPrefecture} prefectureName={selectedPrefectureName} shapes={shapes} setShapes={setShapes} viewMeasure={isMeasurementToolVisible} fabricCanvasRef={fabricCanvasRef} viewDeleteIcon={viewDeleteIcon} setDeleteIcon={setDeleteIcon} viewTrashCan={viewTrashCan} setTrashCan={setTrashCan} handleMeasurementButtonClick={handleMeasurementButtonClick} isMeasurementButtonPressed={isMeasurementButtonPressed}/>
      </div>
      <Modal showFlag={showModal} setShowModal={setShowModal} handlePrefectureChange={handlePrefectureChange} />
      <AlertModal show={showAlertModal} onConfirm={handleAlertConfirm} onCancel={handleAlertCancel}/>
    </div>
  );
};

export default App;
