import React from "react";

const modalContent = {
  background: "white",
  padding: "10px",
  borderRadius: "3px",
};

const Modal = (props) => {
  const closeModal = () => {
    props.setShowModal(false);
  };

  const handleChange = (event) => {
    const prefecture = event.target.value;
    const prefectureName = event.target.getAttribute('data-prefecture');
    props.handlePrefectureChange(prefecture, prefectureName);
  };

  return (
    <>
      {props.showFlag ? (
        <div id="overlay">
          <div id="modalContent">
            <button onClick={closeModal} className="closeBtn"></button>
            <div className="btnArea">
              <label>
                <input type="radio" name="prefecture" value="Aomori" data-prefecture="青森県" onChange={handleChange}/>青森県
              </label>
              <label>
                <input type="radio" name="prefecture" value="Miyagi" data-prefecture="宮城県" onChange={handleChange}/>宮城県
              </label>
              <label>
                <input type="radio" name="prefecture" value="Tochigi" data-prefecture="栃木県" onChange={handleChange}/>栃木県
              </label>
              <label>
                <input type="radio" name="prefecture" value="Gunma" data-prefecture="群馬県" onChange={handleChange}/>群馬県
              </label>
              <label>
                <input type="radio" name="prefecture" value="Chiba" data-prefecture="千葉県" onChange={handleChange}/>千葉県
              </label>
              <label>
                <input type="radio" name="prefecture" value="Niigata" data-prefecture="新潟県" onChange={handleChange}/>新潟県
              </label>
              <label>
                <input type="radio" name="prefecture" value="Ishikawa" data-prefecture="石川県" onChange={handleChange}/>石川県
              </label>
              <label>
                <input type="radio" name="prefecture" value="Shizuoka" data-prefecture="静岡県" onChange={handleChange}/>静岡県
              </label>
              <label>
                <input type="radio" name="prefecture" value="Hyogo" data-prefecture="兵庫県" onChange={handleChange}/>兵庫県
              </label>
              <label>
                <input type="radio" name="prefecture" value="Tottori" data-prefecture="鳥取県" onChange={handleChange}/>鳥取県
              </label>
              <label>
                <input type="radio" name="prefecture" value="Kochi" data-prefecture="高知県" onChange={handleChange}/>高知県
              </label>
              {/* <label>
                <input type="radio" name="prefecture" value="Miyazaki" data-prefecture="宮崎県" onChange={handleChange}/>宮崎県
              </label>
              <label>
                <input type="radio" name="prefecture" value="Kyoto" data-prefecture="京都府" onChange={handleChange}/>京都府
              </label>
              </label> */}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Modal;