import React from 'react';

const AlertModal = ({ show, onConfirm, onCancel }) => {
  if (!show) return null;

  return (
    <div id="overlay">
      <div id="modalContent">
        <div className="headArea">
          <p className="headArea__title">注意</p>
          <p className="headArea__text">作業中の画面が消えます。よろしいですか？</p>
        </div>
        <div className="checkBtnArea">
          <button className="checkBtnArea__btn checkBtnArea__btn--border" onClick={onConfirm}>はい</button>
          <button className="checkBtnArea__btn" onClick={onCancel}>いいえ</button>
        </div>
      </div>
    </div>
  );
};

export default AlertModal;
