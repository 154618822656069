import React from 'react';
import domtoimage from 'dom-to-image';

const ExportButton = ({ prefectureName, fabricCanvasRef, viewDeleteIcon, viewTrashCan }) => {
  const saveImage = (uri) => {
    const link = document.createElement("a");
    if (typeof link.download === "string") {
      link.href = uri;
      link.download = `${prefectureName || 'image'}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(uri);
    }
  };

  const exportImage = () => {
    const canvas = fabricCanvasRef.current;
    // ゴミ箱を非表示にする
    if (viewDeleteIcon && viewTrashCan) {
      viewDeleteIcon.set({ visible: false });
      viewTrashCan.set({ visible: false });
      canvas.renderAll(); // キャンバスを再描画
    }

    // アクティブオブジェクトを解除
    canvas.discardActiveObject();
    canvas.requestRenderAll();

    setTimeout(() => {
      const node = document.getElementsByClassName('exportArea')[0];
      domtoimage.toPng(node)
        .then((dataUrl) => {
          saveImage(dataUrl);
        })
        .catch((error) => {
          console.error('画像のダウンロードに失敗しました。', error);
        });
    }, 50);

    setTimeout(() => {
      // ゴミ箱を再表示する
      if (viewDeleteIcon && viewTrashCan) {
        viewDeleteIcon.set({ visible: true });
        viewTrashCan.set({ visible: true });
        canvas.renderAll(); // キャンバスを再描画
      }
    }, 2000);

  };

  return (
    <button
      onClick={exportImage}
      className='editArea__btn editArea__btn--export'
    >
      <span>ほぞんする</span>
    </button>
  );
};

export default ExportButton;
