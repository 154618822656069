import React, { useEffect, useRef } from 'react';

const MeasurementTool = () => {
    const measur_canvas = useRef(null);
    let firstClick = null;

    useEffect(() => {
        const canvas = measur_canvas.current;
        const ctx = canvas.getContext('2d');

        const drawPoint = (x, y) => {
            ctx.beginPath();
            ctx.arc(x, y, 5, 0, 2 * Math.PI);
            ctx.fillStyle = '#007CBA';
            ctx.fill();
        };

        const handleClick = (event) => {
            const rect = canvas.getBoundingClientRect();
            const x = event.clientX - rect.left;
            const y = event.clientY - rect.top;

            if (!firstClick) {
                firstClick = { x, y };
                drawPoint(x, y);
            } else {
                const dx = x - firstClick.x;
                const dy = y - firstClick.y;
                const distance = Math.sqrt(dx * dx + dy * dy);

                // 150px = 50km に基づいて距離を計算
                const distanceInKm = (distance / 128) * 50;

                const km = Math.floor(distanceInKm);
                // const meters = Math.round((distanceInKm - km) * 1000);

                document.getElementById('distance').innerText = `${km} km`;

                ctx.clearRect(0, 0, canvas.width, canvas.height);
                drawPoint(firstClick.x, firstClick.y);
                drawPoint(x, y);

                ctx.beginPath();
                ctx.moveTo(firstClick.x, firstClick.y);
                ctx.lineTo(x, y);
                ctx.strokeStyle = '#007CBA';
                ctx.lineWidth = 2;
                ctx.stroke();

                firstClick = null;
            }
        };

        // キャンバス上でのクリックイベントを監視
        canvas.addEventListener('click', handleClick);

        return () => {
            canvas.removeEventListener('click', handleClick);
        };
    }, []);

    return (
        <div className='measuringArea'>
            <p id="distance" className='measuringArea__text'>タップして、辺の長さを測りましょう。</p>
            <canvas ref={measur_canvas} id="measur_canvas" width={949} height={540}></canvas>
        </div>
    );
};

export default MeasurementTool;
