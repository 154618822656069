import React, { useEffect, useRef, useState } from "react";
import { fabric } from "fabric";
import ShapeControls from "./ShapeControls";

const Map = ({
  prefecture,
  prefectureName,
  shapes,
  setShapes,
  viewMeasure,
  fabricCanvasRef,
  viewDeleteIcon,
  setDeleteIcon,
  viewTrashCan,
  setTrashCan,
  handleMeasurementButtonClick,
  isMeasurementButtonPressed
}) => {
  const canvasRef = useRef(null);
  const [imageSrc, setImageSrc] = useState("");
  const [remainingShapes, setRemainingShapes] = useState(8); // 初期残り数

  useEffect(() => {
    fabricCanvasRef.current = new fabric.Canvas(canvasRef.current);
    const canvas = fabricCanvasRef.current;

    // 複数選択を無効にする
    canvas.selection = false;

    // 図形が選択されたときに他の図形の選択を解除する
    canvas.on("object:selected", function (e) {
      const selectedObject = e.target;

      canvas.forEachObject(function (obj) {
        if (obj !== selectedObject) {
          obj.set("active", false);
        }
      });

      canvas.discardActiveObject();
      selectedObject.set("active", true);
      canvas.setActiveObject(selectedObject);
      canvas.renderAll();
    });

    if (prefecture) {
      // 都道府県切り替え
      const imagePath = `/path/to/${prefecture}.png`;
      setImageSrc(imagePath);

      const deleteIcon = new Image();
      deleteIcon.src = "/path/to/area_trash-air.png";

      deleteIcon.onload = () => {
        const trashCan = new fabric.Image(deleteIcon, {
          left: canvas.width - deleteIcon.width * 0.6,
          top: canvas.height - deleteIcon.height * 0.6,
          scaleX: 0.6,
          scaleY: 0.6,
          selectable: false,
          hoverCursor: "pointer",
        });
        canvas.add(trashCan);
        setTrashCan(trashCan);


        let isDragging = false;

        canvas.on("object:moving", function (e) {
          isDragging = true;
        });

        canvas.on("mouse:up", function (e) {
          if (!isDragging) return;

          const activeObject = canvas.getActiveObject();
          if (!activeObject) return;

          const pointer = canvas.getPointer(e.e);
          const dropX = pointer.x;
          const dropY = pointer.y;

          const trashCanCoords = trashCan.getBoundingRect();

          if (
            dropX >= trashCanCoords.left &&
            dropX <= trashCanCoords.left + trashCanCoords.width &&
            dropY >= trashCanCoords.top &&
            dropY <= trashCanCoords.top + trashCanCoords.height
          ) {
            // 図形を削除
            canvas.remove(activeObject);
            canvas.discardActiveObject();

            // 残りの図形の数を増やす setShapesの呼び出し
            setShapes((prevShapes) => {
              const updatedShapes = prevShapes.filter(
                (shape) => shape !== activeObject
              );
              return updatedShapes;
            });

            // 残数の数値更新
            setRemainingShapes((prev) => {
              return prev + 1;
            });
          }

          isDragging = false;
        });
      };
    } else {
    }

    // 回転アイコンの画像をロード
    const rotateIcon = new Image();
    rotateIcon.src = "/path/to/rotate-icon.png"; // 画像のパスを指定

    rotateIcon.onload = () => {
      fabric.Object.prototype.controls.mtr = new fabric.Control({
        x: 0,
        y: -0.5, // y座標を調整することで線を非表示にする
        offsetY: -40, // この値を調整して回転アイコンの位置を調整
        cursorStyle: "crosshair",
        actionName: "rotate",
        actionHandler: fabric.controlsUtils.rotationWithSnapping,
        render: function (ctx, left, top, styleOverride, fabricObject) {
          const size = 30; // アイコンのサイズ
          ctx.save();
          ctx.translate(left, top); // 画像の位置を調整
          ctx.drawImage(rotateIcon, -size / 2, -size / 2, size, size);
          ctx.restore();
        },
      });
    };
    // 外枠のデザイン
    fabric.Object.prototype.set({
      borderColor: "#007CBA", //選択枠の色
      borderScaleFactor: 4, //選択枠の太さ
      cornerSize: 12, //コーナーハンドルのサイズ
      cornerColor: "rgba(255, 255, 255, 1)", //コーナーハンドルの色
      transparentCorners: false, //コーナーハンドルの透明にするか（trueだと枠線だけになる）
      cornerStrokeColor: "#007CBA", //コーナーハンドルの輪郭の色
      cornerStyle: "circle", //コーナーハンドルの形（circle or rect）
    });

    // 都道府県切り替え時に残数初期化
    setRemainingShapes(8);

    // ゴミ箱初期表示
    const handleLoad = () => {
      const canvas = fabricCanvasRef.current; // ここでキャンバス要素を取得
      if (!canvas) return;

      const deleteIcon = new Image();
      deleteIcon.src = "/path/to/area_trash-air.png";

      deleteIcon.onload = () => {
        const fabricImage = new fabric.Image(deleteIcon, {
          left: canvas.width - deleteIcon.width * 0.6, // 右下に配置（20pxのマージン）
          top: canvas.height - deleteIcon.height * 0.6,
          scaleX: 0.6, // 横幅を50%に縮小
          scaleY: 0.6, // 高さを50%に縮小
          selectable: false, // 選択不可にする
        });
        // キャンバスに追加
        canvas.add(fabricImage);
        canvas.renderAll();
        setDeleteIcon(fabricImage);
      };
    };
    // キャンバス描画後にゴミ箱ローディング
    const waitForCanvas = () => {
      const canvas = document.getElementById('canvas-container');
      if (canvas) {
        handleLoad();
      } else {
        setTimeout(waitForCanvas, 100); // 100msごとにキャンバスの存在を確認
      }
    };
    setTimeout(waitForCanvas, 100);


    // キャンバスの初期化
    return () => {
      canvas.dispose();
    };
  }, [prefecture, fabricCanvasRef, setDeleteIcon, setTrashCan]);

  useEffect(() => {
    // ゴミ箱の表示状態を切り替える
    if (viewDeleteIcon && viewTrashCan) {
      if(viewMeasure){
        viewDeleteIcon.set("visible", false);
        viewTrashCan.set("visible", false);
      }else{
        viewDeleteIcon.set("visible", true);
        viewTrashCan.set("visible", true);
      }
    }
}, [viewMeasure, viewDeleteIcon, viewTrashCan]);

  const addShape = (shapeType) => {
    const canvas = fabricCanvasRef.current;

    document.querySelectorAll(".upper-canvas").forEach((cvs) => {
      cvs.addEventListener("mouseout", (event) => {
        const outEvent = new MouseEvent("mouseup", { bubbles: true });
        event.target.dispatchEvent(outEvent);
      });
    });

    if (shapes.length >= 8) {
      return;
    }

    const canvasWidth = canvas.getWidth();
    const canvasHeight = canvas.getHeight();

    // 最大サイズ設定
    function applyMaxScaleLimit(shape, maxScaleLimit = 5.3) {
      shape.maxScaleLimit = maxScaleLimit;

      shape.on("scaling", function () {
        const maxScaleX = shape.maxScaleLimit;
        const maxScaleY = shape.maxScaleLimit;

        if (shape.scaleX > maxScaleX) {
          shape.scaleX = maxScaleX;
        }
        if (shape.scaleY > maxScaleY) {
          shape.scaleY = maxScaleY;
        }
      });
    }

    let shape;
    switch (shapeType) {
      // 正三角形
      case "triangle":
        shape = new fabric.Triangle({
          width: 100,
          height: 100,
          fill: "rgba(129, 58, 219, 0.4)",
          stroke: "#9747FF",
          left: (canvasWidth - 50) / 2,
          top: (canvasHeight - 50) / 2,
          minScaleLimit: 0.5,
        });
        applyMaxScaleLimit(shape);
        break;
      case "rect":
        // 正方形(長方形)
        shape = new fabric.Rect({
          width: 100,
          height: 100,
          fill: "rgba(58, 216, 219, 0.5)",
          stroke: "#3AD8DB",
          left: (canvasWidth - 50) / 2,
          top: (canvasHeight - 50) / 2,
          minScaleLimit: 0.25,
        });
        applyMaxScaleLimit(shape);
        break;
      case "parallelogram":
        // 平行四辺形
        shape = new fabric.Polygon(
          [
            { x: 80, y: 0 },
            { x: 200, y: 0 },
            { x: 170, y: 100 },
            { x: 50, y: 100 },
          ],
          {
            fill: "rgba(219, 58, 58, 0.4)",
            stroke: "#DB3A3A",
            left: (canvasWidth - 50) / 2,
            top: (canvasHeight - 50) / 2,
            minScaleLimit: 0.5,
            shapeType: "parallelogram",
          }
        );
        applyMaxScaleLimit(shape);
        break;
      case "trapezoid":
        // 等脚台形
        shape = new fabric.Polygon(
          [
            { x: 40, y: 0 },
            { x: 110, y: 0 },
            { x: 150, y: 100 },
            { x: 0, y: 100 },
          ],
          {
            fill: "rgba(58, 219, 82, 0.4)",
            stroke: "#3ADB52",
            left: (canvasWidth - 50) / 2,
            top: (canvasHeight - 50) / 2,
            minScaleLimit: 0.5,
            shapeType: "trapezoid",
          }
        );
        applyMaxScaleLimit(shape);
        break;
      case "rhombus":
        // 菱形
        shape = new fabric.Polygon(
          [
            { x: 100, y: 0 },
            { x: 200, y: 50 },
            { x: 100, y: 100 },
            { x: 0, y: 50 },
          ],
          {
            fill: "rgba(216, 58, 219, 0.4)",
            stroke: "#D83ADB",
            left: (canvasWidth - 50) / 2,
            top: (canvasHeight - 50) / 2,
            minScaleLimit: 0.5,
            shapeType: "rhombus",
          }
        );
        applyMaxScaleLimit(shape);
        break;
      case "rightTriangle":
        // 直角三角形(追加)
        shape = new fabric.Polygon(
          [
            { x: 0, y: 0 },
            { x: 0, y: 100 },
            { x: 100, y: 100 },
          ],
          {
            fill: "rgba(58, 138, 219, 0.4)",
            stroke: "#3A8ADB",
            left: (canvasWidth - 50) / 2,
            top: (canvasHeight - 50) / 2,
            minScaleLimit: 0.5,
            shapeType: "rightTriangle",
          }
        );
        applyMaxScaleLimit(shape);
        break;
      case "specialTrapezoid":
        // 台形(追加)
        shape = new fabric.Polygon(
          [
            { x: 6, y: 0 },
            { x: 80, y: 0 },
            { x: 100, y: 100 },
            { x: 0, y: 100 },
          ],
          {
            fill: "rgba(219, 193, 58, 0.4)",
            stroke: "#DBC13A",
            left: (canvasWidth - 50) / 2,
            top: (canvasHeight - 50) / 2,
            minScaleLimit: 0.5,
            shapeType: "specialTrapezoid",
          }
        );
        applyMaxScaleLimit(shape);
        break;
      default:
        break;
    }

    if (shape) {
      shape.toObject = (function (toObject) {
        return function () {
          return fabric.util.object.extend(toObject.call(this), {
            customAngleMarks: this.customAngleMarks,
          });
        };
      })(shape.toObject);

      shape.customAngleMarks = true;

      shape._render = (function (_render) {
        return function (ctx) {
          _render.call(this, ctx);

          ctx.save();
          ctx.strokeStyle = "#007CBA";
          ctx.lineWidth = 0.8;
          ctx.setLineDash([1.5, 1]);

          if (this.customAngleMarks) {
            if (this.type === "triangle") {
              const y1 = this.height / 2;
              const x2 = 0;

              const x1 = -this.width / 2;
              const y3 = -this.height / 2;
              const xMid = (x1 + this.width / 2) / 2;
              const yMid = this.height / 2;

              ctx.beginPath();
              ctx.moveTo(x2, y3);
              ctx.lineTo(xMid, yMid);
              ctx.stroke();

              ctx.beginPath();
              ctx.lineTo(x2 + 15, y1);
              ctx.lineTo(x2 + 15, y1 - 15);
              ctx.lineTo(x2, y1 - 15);
              ctx.stroke();
            } else if (this.type === "rect") {
              const x1 = -this.width / 2;
              const y1 = -this.height / 2;
              const x2 = this.width / 2;
              const y2 = this.height / 2;

              ctx.beginPath();
              ctx.lineTo(x1 + 15, y1);
              ctx.lineTo(x1 + 15, y1 + 15);
              ctx.lineTo(x1, y1 + 15);
              ctx.stroke();

              ctx.beginPath();
              ctx.lineTo(x2 - 15, y2);
              ctx.lineTo(x2 - 15, y2 - 15);
              ctx.lineTo(x2, y2 - 15);
              ctx.stroke();

              ctx.beginPath();
              ctx.lineTo(x2 - 15, y1);
              ctx.lineTo(x2 - 15, y1 + 15);
              ctx.lineTo(x2, y1 + 15);
              ctx.stroke();

              ctx.beginPath();
              ctx.lineTo(x1 + 15, y2);
              ctx.lineTo(x1 + 15, y2 - 15);
              ctx.lineTo(x1, y2 - 15);
              ctx.stroke();
            } else if (this.type === "polygon") {
              if (this.shapeType === "rightTriangle") {
                const x1 = -this.width / 2;
                const y2 = this.height / 2;

                ctx.beginPath();
                ctx.moveTo(x1 + 15, y2);
                ctx.lineTo(x1 + 15, y2 - 15);
                ctx.lineTo(x1, y2 - 15);
                ctx.stroke();
              }
              if (this.shapeType === "parallelogram") {
                const width = this.width;
                const height = this.height;

                const offsetX = 30.5;

                const x1 = -width / 2 + offsetX;
                const y1 = height / 2;
                const x2 = x1;
                const y2 = -height / 2;

                ctx.beginPath();
                ctx.moveTo(x1, y1);
                ctx.lineTo(x2, y2);
                ctx.stroke();

                ctx.beginPath();
                ctx.lineTo(x1 + 15, y1);
                ctx.lineTo(x1 + 15, y1 - 15);
                ctx.lineTo(x1, y1 - 15);
                ctx.stroke();
              }
              if (this.shapeType === "trapezoid") {
                const width = this.width;
                const height = this.height;

                const offsetX = 110;

                const x1 = -width / 2 + offsetX;
                const y1 = height / 2;
                const x2 = x1;
                const y2 = -height / 2;

                ctx.beginPath();
                ctx.moveTo(x1, y1);
                ctx.lineTo(x2, y2);
                ctx.stroke();

                ctx.beginPath();
                ctx.lineTo(x1 - 15, y1);
                ctx.lineTo(x1 - 15, y1 - 15);
                ctx.lineTo(x1, y1 - 15);
                ctx.stroke();
              }
              if (this.shapeType === "specialTrapezoid") {
                const width = this.width;
                const height = this.height;

                const offsetX = 79;

                const x1 = -width / 2 + offsetX;
                const y1 = height / 2;
                const x2 = x1;
                const y2 = -height / 2;

                ctx.beginPath();
                ctx.moveTo(x1, y1);
                ctx.lineTo(x2, y2);
                ctx.stroke();

                ctx.beginPath();
                ctx.lineTo(x1 - 15, y1);
                ctx.lineTo(x1 - 15, y1 - 15);
                ctx.lineTo(x1, y1 - 15);

                ctx.stroke();
              }
              if (this.shapeType === "rhombus") {
                const centerX = 0;
                const centerY = 0;
                const width = this.width;
                const height = this.height;

                // 菱形の対角線を描画
                ctx.beginPath();
                ctx.moveTo(centerX, centerY - height / 2);
                ctx.lineTo(centerX, centerY + height / 2);
                ctx.moveTo(centerX - width / 2, centerY);
                ctx.lineTo(centerX + width / 2, centerY);
                ctx.stroke();

                const offsetY = 50;
                const y1 = this.height / 2 - offsetY;
                const x2 = 0;

                ctx.beginPath();
                ctx.lineTo(x2 + 15, y1);
                ctx.lineTo(x2 + 15, y1 - 15);
                ctx.lineTo(x2, y1 - 15);
                ctx.stroke();
              }
            }
          }
          ctx.restore();
        };
      })(shape._render);

      canvas.add(shape);

      shape.bringToFront();
      setRemainingShapes((prev) => prev - 1);
      setShapes([...shapes, shape]);
    }
  };

  const selectInfoClass =
    remainingShapes <= 3 ? "selectInfo__number low" : "selectInfo__number";

  return (
    <>
      <div className="exportArea">
        <canvas
          ref={canvasRef}
          id="canvas-container"
          className="shapes"
          width={949}
          height={533}
          style={{ zIndex: 50 }}
        ></canvas>
        {imageSrc && (
          <img src={imageSrc} alt={prefectureName} className="map" />
        )}
      </div>
      <ShapeControls canvas={fabricCanvasRef.current} addShape={addShape} prefectureName={prefectureName} fabricCanvasRef={fabricCanvasRef} viewDeleteIcon={viewDeleteIcon} viewTrashCan={viewTrashCan} isMeasurementButtonPressed={isMeasurementButtonPressed} handleMeasurementButtonClick={handleMeasurementButtonClick}/>
      <div className="selectInfo">
        {prefectureName && (
          <p>
            作業中：<span>{prefectureName}</span>
          </p>
        )}
        {prefecture && (
          <p style={{ display: viewMeasure ? "none" : "block" }}>
            残り:<span className={selectInfoClass}> {remainingShapes}</span>個
          </p>
        )}
      </div>
    </>
  );
};

export default Map;
