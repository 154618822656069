import React from 'react';
import ExportButton from './ExportCanvas';

const ShapeControls = ({ addShape, prefectureName, fabricCanvasRef, viewDeleteIcon, viewTrashCan, isMeasurementButtonPressed, handleMeasurementButtonClick }) => {
  return (
    <div className="controls">
      <div className="controls__btns">
        <button className='selectSHapes selectSHapes--triangle' onClick={() => addShape('triangle')}><span>二等辺三角形</span></button>
        <button className='selectSHapes selectSHapes--rightTriangle' onClick={() => addShape('rightTriangle')}><span>直角三角形</span></button>
        <button className='selectSHapes selectSHapes--rect' onClick={() => addShape('rect')}><span>長方形</span></button>
        <button className='selectSHapes selectSHapes--trapezoid' onClick={() => addShape('trapezoid')}><span>台形①</span></button>
        <button className='selectSHapes selectSHapes--specialTrapezoid' onClick={() => addShape('specialTrapezoid')}><span>台形②</span></button>
        <button className='selectSHapes selectSHapes--parallelogram' onClick={() => addShape("parallelogram")}><span>平行四辺形</span></button>
        <button className='selectSHapes selectSHapes--rhombus' onClick={() => addShape('rhombus')}><span>ひし形</span></button>
      </div>
      <div className='editArea'>
        <ExportButton prefectureName={prefectureName} fabricCanvasRef={fabricCanvasRef} viewDeleteIcon={viewDeleteIcon} viewTrashCan={viewTrashCan} />
        <button className='editArea__btn editArea__btn--measurement' fabricCanvasRef={fabricCanvasRef} onClick={handleMeasurementButtonClick}><span>{isMeasurementButtonPressed ? '測定をやめる' : '測定を始める '} </span></button>
      </div>
    </div>
  );
};

export default ShapeControls;
